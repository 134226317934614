<template>
  <div class="login">
    <Loader v-if="(!userProfile || !userProfile.id) || performingRequest" />
    <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
      <div class="dashboard__container--body--col shiftCard mt-5">
        
        <div class="whiteBack">
          <h3>Congratulations</h3>
          <div class="mt-3">Based on the information you provided on your application, you meet the requirements for this position. Here is what happens next:</div>
          <div class="mt-5">
            <div class="mb-3">We are emailing you a Conditional Offer of Employment.</div>
            <div class="mb-3">Your background check has been ordered. Please check your email from 5 Star Background Checks. Click the link to confirm your information... it only takes a few seconds to complete.</div>
            <div class="mb-3">
              Once we have the results of your background check, we will be in touch regarding the next steps.
            </div>
            <div class="mb-3">If you have any questions or concerns, please email hr@jumpstaffing.com</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'authNY',
  data: () => ({
    performingRequest: false,
  }),
  components: {
    Loader
  },
  // async mounted () {
  //   await this.$store.dispatch("fetchUserProfile")
  // },
  computed: {
  ...mapState(['currentUser', 'userProfile']),
  },
  methods: {

  },
  beforeDestroy () {
    this.performingRequest = false
  }
}
</script>